import React from 'react';
import gsap from 'gsap';
import { Box, Button, colors, Grid, Paper, Typography, Card, CardContent, Chip, IconButton } from '@mui/material';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import RocketIcon from '@mui/icons-material/Rocket';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EngineeringIcon from '@mui/icons-material/Engineering';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

function ProjectWizardScreen(props) {
    const [gameMode, setGameMode] = React.useState('sandbox');
    const [sandboxLocation, setSandboxLocation] = React.useState('starbase');
    const [sandboxScenario, setSandboxScenario] = React.useState('surface');
    const [sandboxVehicle, setSandboxVehicle] = React.useState('starhopper');

    const modelRef = React.useRef(null);
    const modelTimeline = React.useRef(null);

    const scenarios = {
        'training': [
            {
                'id': '20m_hop',
                'name': '20m Hop',
                'video_url': 'https://www.youtube.com/watch?v=aChE86D0c_A',
                'vessel': 'Starhopper',
                'location': 'Boca Chica, Earth',
                'status': 'new',
                'rating': null,
            },
            {
                'id': '150m_hop',
                'name': '150m Test Flight',
                'video_url': 'https://www.youtube.com/watch?v=bYb3bfA6_sQ',
                'vessel': 'Starhopper',
                'location': 'Boca Chica, Earth',
                'status': 'new',
                'rating': null,
            },
        ],
    };

    function animateModel() {
        if (!modelRef.current) {
            return;
        }

        if (!modelTimeline.current) {
            modelTimeline.current = gsap.timeline().to(modelRef.current, {
                'cameraOrbit': '0deg 84deg',
                'fieldOfView': '34deg',
            }).duration(0.4);

            return;
        }

        if (modelTimeline.current.reversed() && modelTimeline.current.progress() === 0) {
            modelTimeline.current.restart();
            return;
        }

        if (modelTimeline.current.progress() < 1) {
            return;
        }

        modelTimeline.current.reverse();
    }

    return <React.Fragment>
        <Paper elevation={ 4 } sx={{
            'alignSelf': 'start',
            'borderRadius': 0,
            'columnGap': 1.5,
            'display': 'flex',
            'justifyContent': 'center',
            'px': 2,
            'py': 1.5,
            'width': '100%',
        }}>
            <Button variant='outlined' disabled sx={{ 'opacity': '0.28' }}>Training&nbsp;<EngineeringIcon/></Button>

            { ['sandbox'].map((mode, i) => <Button key={ i } variant={ gameMode === mode ? 'contained': 'outlined' } disabled={ gameMode === mode } onClick={ () => setGameMode(mode) } sx={{
                '&.Mui-disabled': {
                    'backgroundColor': colors.amber['500'],
                    'color': '#000000',
                    'fontWeight': '600',
                },
            }}>{ mode }</Button>) }

            <Button variant='outlined' disabled sx={{ 'opacity': '0.28' }}>Challenges&nbsp;<EngineeringIcon/></Button>
            <Button variant='outlined' disabled sx={{ 'opacity': '0.28' }}>Replays&nbsp;<EngineeringIcon/></Button>
        </Paper>

        <Grid container alignSelf='start' maxWidth='720px' ml='auto' mr='auto' px={ 3 }>
            <Grid item xs={ 12 } height='340px'>
                <model-viewer src='./starhopper.glb'
                    auto-rotate auto-rotate-delay='0' rotation-per-second='0.24rad' loading='eager'
                    camera-target='0.003m 0.075m auto' camera-orbit='0deg 108deg' field-of-view='28deg'
                    disable-tap disable-pan disable-zoom shadow-intensity='0'
                    style={{
                        'width': '100%',
                        'height': '100%',
                        'marginLeft': 'auto',
                        'marginRight': 'auto',
                        'transform': 'rotate(-9deg) translateY(10px)',
                        'pointerEvents': 'none',
                    }}
                    ref={ modelRef }
                />
            </Grid>

            <Grid item xs={ 12 } sx={{
                'transform': 'translateY(-40px)',
            }}>
                <Typography color={ colors['amber']['500'] } component='div' variant='h4' fontWeight='600' mb={ 4 }>{ gameMode.toUpperCase() }</Typography>

                <Box height='220px' px={ 2 } display={ gameMode === 'training' ? 'flex' : 'none' } justifyContent='center' columnGap={ 4 }>
                    { scenarios['training'].map((scenario, i) => <Card key={ i } sx={{
                        'background': 'linear-gradient(45deg, rgba(33, 33, 33, 0.55), rgba(66, 66, 66, 0.55))',
                        'border': '2px solid rgba(33, 33, 33, 0.55)',
                        'display': 'inline-flex',
                        'userSelect': 'none',
                        '&:hover': {
                            'border': `2px solid ${ colors.amber['500'] }`,
                            'px': 0,
                            'py': 0,
                        },
                    }} onMouseEnter={ () => {
                        document.body.style.cursor = 'pointer';
                        animateModel();
                    }} onMouseLeave={ () => {
                        document.body.style.cursor = 'initial';
                    }} onClick={ () => {
                        props['on_play_button_clicked']({
                            'game_mode': gameMode,
                            'scenario': scenario['id'],
                        });
                    }}>
                        <CardContent>
                            <Typography component='div' variant='h6' textAlign='center' mb={ 1 } width='250px'>
                                { scenario['name'] }

                                <IconButton onClick={ () => window.open(scenario['video_url'], '_blank') } sx={{
                                    'ml': 1.5,
                                }}>
                                    <OndemandVideoOutlinedIcon sx={{ 'color': colors['amber']['500'], 'fontSize': '30px' }}/>
                                </IconButton>
                            </Typography>
                            <Typography component='div' variant='body1' display='flex' alignItems='center'><RocketIcon/>&nbsp;{ scenario['vessel'] }</Typography>
                            <Typography component='div' variant='body1' display='flex' alignItems='center' mt={ 1 }><LocationOnIcon/>&nbsp;{ scenario['location'] }</Typography>

                            <Box width='100%' borderTop={ `2px solid ${colors['grey']['500']}` } display='flex' justifyContent='center' columnGap={ 1.5 } mt={ 1.5 } pt={ 2 }>
                                { scenario['status'] === 'complete' ? <Chip icon={ <DoneOutlineIcon/> } label='Complete'/> : scenario['status'] === 'in_progress' ? <Chip label='In Progress'/> : <Chip label='New'/> }
                                <Chip icon={ <StarOutlineIcon/> } label={ `${ scenario['rating'] ?? 0 } / 100` }/>
                            </Box>
                        </CardContent>
                    </Card>) }
                </Box>

                <Grid container height='220px' px={ 2 } display={ gameMode === 'sandbox' ? 'flex' : 'none' } columnGap={ 2 } rowGap={ 0.5 }>
                    { [{
                        'state': sandboxLocation,
                        'stateSetter': setSandboxLocation,
                        'label': 'location',
                        'options': [
                            { 'label': 'Starbase, Earth', 'value': 'starbase', 'available': true },
                            { 'label': 'Spaceport One, Earth', 'value': 'spaceport', 'available': false },
                        ],
                    }, {
                        'state': sandboxScenario,
                        'stateSetter': setSandboxScenario,
                        'label': 'scenario',
                        'options': [
                            { 'label': 'On Surface', 'value': 'surface', 'available': true },
                            { 'label': '400m Above Surface', 'value': '400m', 'available': false },
                        ],
                    }, {
                        'state': sandboxVehicle,
                        'stateSetter': setSandboxVehicle,
                        'label': 'vehicle',
                        'options': [
                            { 'label': 'Starhopper', 'value': 'starhopper', 'available': true },
                            { 'label': 'Starship MK8', 'value': 'starship_mk8', 'available': false },
                        ],
                    }].map((section, i) => <Grid item key={ i } xs={ 12 } display='flex' alignItems='center' columnGap={ 2 }>
                        <Typography component='span' variant='h5' mr={ 1 } width='130px' sx={{
                            'userSelect': 'none',
                        }}>
                            { section['label'].toUpperCase() }:
                        </Typography> 

                        { section['options'].map((option, i) => <Button key={ i }
                            variant={ section['state'] === option['value'] ? 'contained': 'outlined' }
                            disabled={ section['state'] === option['value'] || !option['available'] }
                            onClick={ () => section['stateSetter'](option['value']) }
                            sx={{
                                '&.Mui-disabled': option['available'] ? {
                                    'backgroundColor': colors.amber['500'],
                                    'color': '#000000',
                                    'fontWeight': '600',
                                } : undefined,
                                'minWidth': '235px',
                                'textTransform': 'none',
                                'opacity': option['available'] ? 1 : 0.35,
                            }}>
                                <Box ml='auto' mr='auto'>
                                    { option['label'] }
                                </Box>

                                { !option['available'] ? <Box ml='auto'>
                                    <EngineeringIcon/>
                                </Box> : undefined  }
                            </Button>
                        )}
                    </Grid> )}

                    <Grid item xs={ 12 } display='flex' justifyContent='center' pt={ 1 }>
                        <IconButton color='success' sx={{
                            'color': 'success',
                        }} onMouseEnter={ () => animateModel() } onClick={ () => {
                            props['on_play_button_clicked']({
                                'game_mode': gameMode,
                                'location': sandboxLocation,
                                'scenario': sandboxScenario,
                                'vehicle': sandboxVehicle,
                            });
                        }}>
                            <PlayCircleFilledWhiteOutlinedIcon sx={{ 'fontSize': '48px' }}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>;
}

export default ProjectWizardScreen;
