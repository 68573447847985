import React from 'react';
import { Alert, AlertTitle, Box, Button, colors, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

function SigninScreen(props) {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [usernameError, setUsernameError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [wasSignInFailed, setWasSignInFailed] = React.useState(false);
    const propIsSignInError = props['isSignInError'];

    React.useLayoutEffect(() => {
        if (!propIsSignInError) {
            return;
        }

        setUsername('');
        setPassword('');
        setWasSignInFailed(true);
        setUsernameError(true);
        setPasswordError(true);
    }, [propIsSignInError]);

    function onUsernameChange(e) {
        setUsername(e.target.value);
        setUsernameError(false);
        setWasSignInFailed(false);
    }

    function onPasswordChange(e) {
        setPassword(e.target.value);
        setPasswordError(false);
        setWasSignInFailed(false);
    }

    function onSignInClick() {
        let allowClick = true;

        if (!username || username.length < 1) {
            setUsernameError(true);
            allowClick = false;
        } else {
            setUsernameError(false);
        }

        if (!password || password.length < 1) {
            setPasswordError(true);
            allowClick = false;
        } else {
            setPasswordError(false);
        }

        if (!allowClick) {
            return;
        }

        props['onSignInClick'](username, password);
    }

    return <Paper elevation={ 5 } sx={{
        'px': 3,
        'py': 3,
        'maxWidth': '488px',
    }}>
        <Grid item xs={ 12 } mb={ 3 } textAlign='center' display='flex' justifyContent='center'>
            <Alert severity='info' variant='filled' icon={ false }>
                <AlertTitle>
                    🧪&nbsp;
                    <Typography component='span' color={ colors.grey['300'] } variant='body1' fontWeight='500'>
                        avionic.
                    </Typography>

                    <Typography component='span' color={ colors.amber['500'] } variant='body1' fontWeight='500'>
                        dev
                    </Typography>

                    &nbsp;is available for preview!
                    Support us on <b><span style={{ 'color': '#fff' }}>Patreon</span></b> to play.
                </AlertTitle>

                <Box mt={ 2 } mb={ 1 } sx={{ 'transform': 'translateX(-18px)' }}>
                    <Button color='patreon' variant='contained' size='medium' onClick={ () => window.open('https://patreon.com', '_blank') } sx={{
                        'fontWeight': '500',
                        'letterSpacing': '3px',
                    }}>
                        PATREON
                    </Button>
                </Box>
            </Alert>
        </Grid>

        <Grid item xs={ 12 } textAlign='center' display='flex' alignItems='center' justifyContent='center'>
            <LockOpenOutlinedIcon/> 

            <Typography component='span' variant='h6' fontWeight='400' ml={ 2 }>
                Please sign in to continue 
            </Typography>
        </Grid>

        <Grid item xs={ 12 } mt={ 3 }>
            <Grid container columnGap={ 2 } rowGap={ 2.5 } ml='auto' mr='auto'>
                <Grid item xs={ 12 } textAlign='center'>
                    <TextField fullWidth label='User Name' variant='outlined' value={ username } onChange={ onUsernameChange } disabled={ props['isSignInLocked'] } error={ usernameError || wasSignInFailed }/>
                </Grid>

                <Grid item xs={ 12 } textAlign='center'>
                    <TextField fullWidth label='Password' type='password' variant='outlined' value={ password } onChange={ onPasswordChange } disabled={ props['isSignInLocked'] } error={ passwordError || wasSignInFailed }/>
                </Grid>

                <Grid item xs={ 12 } textAlign='center' display='flex' columnGap={ 1 } justifyContent='center'>
                    <Button color='patreon' variant='contained' disabled={ true } onClick={ onSignInClick }>
                        Sign-in With&nbsp;
                        <Typography sx={{
                            'fontWeight': '500',
                            'letterSpacing': '3px',
                            'fontSize': '13px',
                        }}>Patreon</Typography>
                    </Button>

                    <Button variant='outlined' disabled={ props['isSignInLocked'] } onClick={ onSignInClick }>
                        { props['isSignInLocked'] ? <CircularProgress size={ 24 } sx={{ 'opacity': 0.12 }} /> : 'Sign-in' }
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Paper>;
}

export default SigninScreen;
