import React from 'react';
import { Box, LinearProgress, Skeleton } from '@mui/material';

function VscodeScreen(props) {
    const [isIframePreloaded, setIsIframePreloaded] = React.useState(false);
    const [vscodeIsCommunicationReady, setVscodeIsCommunicationReady] = React.useState(false);
    const [vscodeIsUiReady, setVscodeIsUiReady] = React.useState(false);
    const iframeRef = React.useRef(null);
    const vscodeMessageSource = React.useRef(null);
    const vscodeOrigin = document.location.hostname === 'localhost' ? 'http://localhost:3002' : 'https://avionic-dev-editor.pages.dev';
    const propOnLoad = props['onLoad'];
    const propOnExtensionTokenExpired = props['onExtensionTokenExpired'];
    const propJwt = props['jwt'];

    const messageListener = React.useCallback((message) => {
        if (message.origin !== vscodeOrigin) {
            return;
        }

        if (message.data.type !== 'vscode_extension.from' || typeof message.data.data !== 'string') {
            return;
        }

        vscodeMessageSource.current = message.source;

        if (message.data.data === 'communication_ready') {
            setVscodeIsCommunicationReady(true);
            return;
        }

        if (message.data.data === 'ui_ready') {
            setVscodeIsUiReady(true);
            return;
        }

        if (message.data.data === 'token_expired') {
            propOnExtensionTokenExpired();
            return;
        }
    }, [propOnExtensionTokenExpired, vscodeOrigin]);

    React.useLayoutEffect(() => {
        if (!isIframePreloaded) {
            vscodeMessageSource.current = null;
            setVscodeIsCommunicationReady(false);
            setVscodeIsUiReady(false);
            return;
        }

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, [isIframePreloaded, messageListener]);

    React.useLayoutEffect(() => {
        if (vscodeIsCommunicationReady !== true || vscodeIsUiReady !== true) {
            return;
        }

        if (typeof propOnLoad === 'function') {
            propOnLoad();
        }
    }, [propOnLoad, vscodeIsCommunicationReady, vscodeIsUiReady]);

    React.useLayoutEffect(() => {
        if (!vscodeIsCommunicationReady || !vscodeMessageSource.current || typeof propJwt !== 'string') {
            return;
        }

        vscodeMessageSource.current.postMessage({
            'type': 'vscode_extension.to',
            'data': {
                'jwt'     : propJwt,
                'scenario': 'starhopper-tutorial',
                'env'     : window.origin.includes('localhost') ? 'local' : 'remote',
            },
        }, vscodeOrigin);
    }, [vscodeOrigin, vscodeIsCommunicationReady, propJwt]);

    return <React.Fragment>
        <iframe title='editor' src={ vscodeOrigin } frameBorder='0' ref={ iframeRef } style={{
            'border': '0',
            'overflow': 'hidden',
            'margin': '0',
            'position': 'absolute',
            'left': '0',
            'right': '0',
            'top': '0',
            'bottom': '0',
            'width': '100%',
            'height': '100%',
            'visibility': vscodeIsUiReady ? 'visible' : 'hidden',
        }} onLoad={ () => setIsIframePreloaded(true) }/>

        { !vscodeIsUiReady ? <React.Fragment>
            <Box width='100%' height='2px' sx={{
                'transform': 'translateY(-2px)',
            }}>
                <LinearProgress/>
            </Box>

            <Box width='100%' height='calc(100vh - 50px)' sx={{
                'opacity': 0.4,
            }}>
                <Box width='100%' height='30px'>
                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                </Box>

                <Box display='flex' width='100%' height='calc(100% - 30px - 24px)' pt='3px'>
                    <Box width='50px' height='100%' pr='3px'>
                        <Skeleton variant='rectangular' width='100%' height='100%'/>
                    </Box>

                    <Box width='197px' height='100%' pr='3px'>
                        <Skeleton variant='rectangular' width='100%' height='100%'/>
                    </Box>

                    <Box width='100%' height='100%'>
                        <Box width='100%' height='calc(100% - 195px)' pb='3px'>
                            <Box display='flex' width='100%' height='37px' pb='3px'>
                                <Box width='120px' height='100%' pr='3px'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>

                                <Box width='120px' height='100%' pr='3px'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>

                                <Box width='120px' height='100%' pr='3px'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>

                                <Box width='100%' height='100%'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>

                                <Box width='66px' height='100%' pl='3px'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>
                            </Box>

                            <Box width='100%' height='23px'>
                                <Skeleton variant='rectangular' width='100%' height='100%'/>
                            </Box>

                            <Box display='flex' width='100%' height='calc(100% - 37px - 23px)' pt='3px'>
                                <Box width='100%' height='100%' pr='3px'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>

                                <Box width='120px' height='100%'>
                                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                                </Box>
                            </Box>
                        </Box>

                        <Box width='100%' height='195px'>
                            <Skeleton variant='rectangular' width='100%' height='100%'/>
                        </Box>
                    </Box>
                </Box>

                <Box width='100%' height='24px' pt='3px'>
                    <Skeleton variant='rectangular' width='100%' height='100%'/>
                </Box>
            </Box>
        </React.Fragment> : null }
    </React.Fragment>;
}

export default VscodeScreen;
