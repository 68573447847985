import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, colors, CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';

const darkTheme = createTheme({
    'components': {
        'MuiCssBaseline': {
            'styleOverrides': `
                * { /* Firefox */
                    scrollbar-width: auto;
                    scrollbar-color: ${ colors.grey[800] } ${ colors.grey[900] };
                }

                body::-webkit-scrollbar {
                    width: 12px;
                    height: 12px;
                    background-color: ${ colors.grey[900] };
                }

                body::-webkit-scrollbar-thumb {
                    background-color: ${ colors.grey[800] };
                }

                body::-webkit-scrollbar-thumb:hover {
                    background-color: ${ colors.grey[800] };
                }
            `,
        },
    },
    'palette': {
        'mode': 'dark',
        'primary': {
            'main': '#fff',
        },
        'patreon': {
            'main': '#FF424D',
            'contrastText': '#fff',
        }
    },
    'typography': {
        'fontFamily': 'Source Code Pro',
    },
});

const root_node = document.getElementById('root');
const root = ReactDOM.createRoot(root_node);

root_node.style.minWidth='720px';
root_node.style.minHeight = '100vh';
root_node.style.position = 'relative';

root.render(<ThemeProvider theme={ darkTheme }>
    <CssBaseline/>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
</ThemeProvider>);