import React from 'react';
import SigninScreen from './SigninScreen';
import ProjectWizardScreen from './ProjectWizardScreen';
import VscodeScreen from './VscodeScreen';

import { useHistory, Switch, Route } from 'react-router-dom';
import { AppBar, Box, Button, colors, Toolbar, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

function TopBar(props) {
    return <AppBar position='static'>
        <Toolbar style={{
            'borderBottom': `2px solid ${ colors.grey[700] }`,
            'minHeight': 46,
            'paddingLeft': 8,
            'paddingRight': 8,
        }} sx={{
            'justifyContent': 'space-between',
        }}>
            <Box>
                <Typography component='span' color={ colors.grey['500'] } variant='h6' fontWeight='500' letterSpacing='-4px' mr='4px'>
                    { '//' }
                </Typography>

                <Typography component='span' color={ colors.grey['300'] } variant='h6' fontWeight='400'>
                    avionic.
                </Typography>

                <Typography component='span' color={ colors.amber['500'] } variant='h6' fontWeight='500'>
                    dev
                </Typography>

                <Typography component='span' color={ colors.grey['500'] } variant='h6'>
                    /
                </Typography>

                { props['route'] ? <Typography component='span' variant='h6' fontWeight='500'>
                    { props['route'].slice(1) }
                </Typography> : null }
            </Box>

            <Box>
                <Typography component='span' color={ colors.grey['500'] } fontSize={ 10 } fontWeight='400' mr={ 3.5 }>
                    ver. 2024-Jan-11
                </Typography>

                👨‍🚀 <code>{ props['username'] ?? 'Guest' }</code>

                { props['username'] ? <Button variant='outlined' size='small' disabled={ props['isSignInLocked'] } onClick={ props['onSignOutClick'] } sx={{
                    'ml': 2.5,
                    'opacity': 0.7,
                    'fontSize': 10,
                }}>
                    <LogoutIcon sx={{ 'fontSize': 12 }}/>&nbsp;Sign-out
                </Button> : null }
            </Box>
        </Toolbar>
    </AppBar>;
}

function App() {
    const authServiceUrl = 'https://auth-staging.avionic.dev';
    const [currentRoute, setCurrentRoute] = React.useState(null);
    const [credentials, setCredentials] = React.useState(null);
    const [displayUsername, setDisplayUsername] = React.useState(null);
    const [isLoggedIn, setIsLoggedIn] = React.useState(null);
    const [isTryingLogIn, setIsTryingLogIn] = React.useState(false);
    const [isLogInFailed, setIsLogInFailed] = React.useState(false);
    const [jwt, setJwt] = React.useState(localStorage.getItem('jwt'));
    const history = useHistory();

    function setCurrentRouteDelayed(route) {
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                setCurrentRoute(route);
            });
        });
    }

    const vscodeOnLoad = React.useCallback(() => {
    }, []);

    const verifyJwt = React.useCallback(async (jwt) => {
        const response = await fetch(`${ authServiceUrl }/jwt-verify`, {
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + jwt,
            },
        });

        if (!response.ok || response.status !== 200) {
            return false;
        }

        const jwtDecoded = await response.json();
        return jwtDecoded;
    }, []);

    const generateJwt = React.useCallback(async (username, password) => {
        const response = await fetch(`${ authServiceUrl }/jwt-generate`, {
            'method': 'POST',
            'body': `user_name=${ username }&password=${ password }`,
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        });

        if (!response.ok || response.status !== 200) {
            return false;
        }

        const jwt = await response.text();
        return jwt;
    }, []);

    const topBar = React.useMemo(() => {
        return <TopBar username={ displayUsername } route={ currentRoute } onSignOutClick={ () => setIsLoggedIn(false) }/>;
    }, [currentRoute, displayUsername]);

    const signinScreen = React.useMemo(() => {
        if (isLoggedIn !== false) {
            return;
        }

        return <SigninScreen isSignInLocked={ isTryingLogIn } isSignInError={ isLogInFailed } onSignInClick={ (username, password) => {
            setCredentials({
                'username': username,
                'password': password,
            });
            setIsTryingLogIn(true);
        }}/>;
    }, [isTryingLogIn, isLoggedIn, isLogInFailed]);

    const projectWizardScreen = React.useMemo(() => {
        if (isLoggedIn !== true || typeof jwt !== 'string') {
            return;
        }

        return <ProjectWizardScreen on_play_button_clicked={ (options) => {
            setCurrentRouteDelayed('/workbench');
        }}/>;
    }, [isLoggedIn, jwt]);

    const vscodeScreen = React.useMemo(() => {
        if (isLoggedIn !== true || typeof jwt !== 'string') {
            return;
        }

        return <VscodeScreen jwt={ jwt } onLoad={ vscodeOnLoad } onExtensionTokenExpired={ () => {
            setIsLoggedIn(false);
        }}/>;
    }, [isLoggedIn, jwt, vscodeOnLoad]);

    // Handle user sign-in:
    React.useLayoutEffect(() => {
        if (isTryingLogIn !== true || !credentials) {
            return;
        }

        setIsLogInFailed(false);

        window.setTimeout(() => {
            generateJwt(credentials['username'], credentials['password']).then((result) => {
                if (typeof result !== 'string') {
                    setIsLogInFailed(true);
                    setCurrentRouteDelayed('/sign-in');
                    return;
                }

                setDisplayUsername(credentials['username']);
                setJwt(result);
                setIsLogInFailed(false);
                setIsLoggedIn(true);
                setCurrentRouteDelayed('/projects');
            }).finally(() => {
                setIsTryingLogIn(false);
                setCredentials(null);
            });
        }, 500);
    }, [credentials, isTryingLogIn, generateJwt]);

    // Logout:
    React.useEffect(() => {
        if (isLoggedIn !== false) {
            return;
        }

        setJwt(null);
        setDisplayUsername(null);
        setCurrentRouteDelayed('/sign-in');
    }, [isLoggedIn]);

    // Save the token to Local Storage:
    React.useEffect(() => {
        if (typeof jwt !== 'string') {
            window.localStorage.removeItem('jwt');
            return;
        }

        window.localStorage.setItem('jwt', jwt);
    }, [jwt]);

    React.useLayoutEffect(() => {
        if (currentRoute === null) {
            history.replace('');
            setCurrentRouteDelayed('/');
            return;
        }

        if (currentRoute !== null && history.location.pathname !== currentRoute) {
            history.push(currentRoute);
            return;
        }

        // Search for the existing JWT on app start:
        const localStorageJwt = localStorage.getItem('jwt');

        if (typeof localStorageJwt !== 'string') {
            setIsLoggedIn(false);
            setJwt(null);
            setCurrentRouteDelayed('/sign-in');
            return;
        }

        verifyJwt(localStorageJwt).then((result) => {
            if (typeof result['userName'] !== 'string') {
                setIsLoggedIn(false);
                setJwt(null);
                setCurrentRouteDelayed('/sign-in');
                return;
            }

            setJwt(localStorageJwt);
            setIsLoggedIn(true);
            setDisplayUsername(result['userName']);
            setCurrentRouteDelayed('/projects');
        });
    }, [currentRoute, verifyJwt, history]);

    history.listen((location) => {
        setCurrentRouteDelayed(location.pathname);
    });

    return <React.Fragment>
        { topBar }

        <Box width='100%' minHeight='calc(100vh - 46px)' display='flex' flexWrap='wrap' alignItems='center' justifyContent='center' position='relative'>
            <Switch>
                <Route path='/sign-in'>
                    { signinScreen }
                </Route>

                <Route path='/projects'>
                    { projectWizardScreen }
                </Route>

                <Route path='/workbench'>
                    { vscodeScreen }
                </Route>
            </Switch>
        </Box>
    </React.Fragment>;
}

export default App;
